import React, {useEffect} from 'react'
import styled from 'styled-components'
import {useStore} from '../../utils/store'
import Cross from '../../assets/svg/circleCross.inline.svg'
import Stockists from '../atoms/Stockists'

const Holder = styled.div`
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: flex-end;
  transform: ${props => props.show ? 'translateY(0%)' : 'translateY(200%)'};
  transition: transform 0.25s ease-in-out;
  @media ( ${props => props.theme.breakpoints.md} ) {
    align-items: center;
    justify-content: center;
  }
`

const Inner = styled.div`
  box-shadow: 10px 10px 10px 10px rgb(0 0 0 / 10%);
  position: relative;
  background-color: ${props => props.theme.colours.white};
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
  @media ( ${props => props.theme.breakpoints.md} ) {
    width: unset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 56rem;
    overflow: hidden;
  }

  h2 {
    margin: 2rem 2rem 0 2rem;
    @media ( ${props => props.theme.breakpoints.md} ) {
      margin: 2rem 2rem 0 1rem;
    }
  }

  .submit-message {
    margin: 3rem 0;
    @media ( ${props => props.theme.breakpoints.md} ) {
      margin: 1rem 0;
    }
  }

  form {
    input[type="submit"] {
      margin-top: 1.5rem;
    }
  }
`

const CloseHolder = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;

  button {
    svg {
      width: 2rem;
      display: block;
    }
  }

  @media ( ${props => props.theme.breakpoints.md} ) {
    position: absolute;
  }
`

function PopupForm() {
    const retailerOpen = useStore(state => state.retailerOpen)
    const closeRetailer = useStore(state => state.closeRetailer)

    useEffect(() => {
        if (retailerOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }
    })

    return (
        <Holder show={retailerOpen}>
            <Inner>
                <h2>Choose your<br/> preferred retailer.</h2>
                <Stockists form="true" close={() => closeRetailer()}/>
                <CloseHolder>
                    <button aria-label="Close the pop-up" onClick={() => {
                        closeRetailer()
                    }} className="button link">
                        <Cross/>
                    </button>
                </CloseHolder>
            </Inner>
        </Holder>
    )
}

export default PopupForm