import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Container from '../atoms/Container'
import Logo from '../../assets/svg/logo.inline.svg'
import {Link} from 'gatsby'
import SubscribeForm from './SubscribeForm'
import Arrow from '../../assets/svg/l-arrow.inline.svg'
import Facebook from '../../assets/svg/facebook.inline.svg'
import Instagram from '../../assets/svg/instagram.inline.svg'

const Holder = styled.div`
  padding-top: 1rem;
  background-color: ${props => props.theme.footBg};
  color: ${props => props.theme.footText};

  a {
    text-decoration: none;
  }

  .cred {
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    ${(props) => props.theme.fluidType(-4)};
    @media ( ${props => props.theme.breakpoints.md} ) {
      padding: 1rem 0;
      gap: 0.5rem;
      flex-direction: row;
      align-items: center;
    }

    p {
      margin: 0;
    }
  }

  .Footer {
    padding-top: 2rem;
  }
`

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  row-gap: 2rem;
  align-items: center;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 2fr 1fr 1fr;
    grid-auto-rows: unset;
    column-gap: 2rem;
    align-items: flex-start;
  }

  .footer-buttons {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    button {
      width: 100%;
      background-color: ${props => props.theme.footText};
      color: ${props => props.theme.footBg};
      padding: 0.75rem 1rem;

      svg {
        transform: rotateZ(90deg);
        margin-left: 0.5rem;

        path {
          fill: ${props => props.theme.footBg};
        }
      }
    }
  }
`

const Social = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 2rem;
  align-items: flex-start;
  justify-content: space-evenly;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin: 0;
  }

  a {
    color: ${props => props.theme.footText};
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) => props.theme.fluidType(-1)};
    border-radius: 2rem;

    &:active {
      text-decoration: none;
      transform: scale(0.975);
    }

    svg {
      margin-right: 0.5rem;
      height: auto;
      width: 1.2rem;

      path {
        fill: ${props => props.theme.footText};
      }
    }
  }
`

const Inner = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 2rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    gap: 1rem;
  }

  a {
    grid-column: 1/-1;
    padding: 0.5rem;
    @media ( ${props => props.theme.breakpoints.md} ) {
      grid-column: unset;
      padding: 0;
    }

    svg {
      height: auto;
      width: 100%;

      path, polygon, rect {
        fill: ${props => props.theme.footText};
      }
    }
  }


  ul {
    margin: 0;
    list-style: none;
    display: none;
    @media ( ${props => props.theme.breakpoints.md} ) {
      display: block;
    }

    li:first-child, button {
      ${(props) => props.theme.fluidType(0)};
      font-variation-settings: 'wght' 550, 'GRAD' 88, 'wdth' 375;
    }
  }
`

function Footer({showFooter}) {
    const [, setShowTopBtn] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true)
            } else {
                setShowTopBtn(false)
            }
        })
    }, [])
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    return (
        <>
            {!showFooter &&
                <Holder>
                    <Container>
                        <footer className="Footer">
                            <Wrapper>
                                <Inner>
                                    <Link to="/"><Logo/></Link>
                                    <ul>
                                        <li><Link to="/products">Products</Link></li>
                                        <li><Link state={{active: 'top-of-bed'}} to="/products">Top of Bed</Link></li>
                                        <li><Link state={{active: 'quilt-covers'}} to="/products">Quilt Covers</Link>
                                        </li>
                                        <li><Link state={{active: 'sheets'}} to="/products">Sheets</Link></li>
                                        <li><Link state={{active: 'towels'}} to="/products">Towels</Link></li>
                                        <li><Link state={{active: 'accessories'}} to="/products">Accessories</Link></li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Link to="/">Dickies</Link>
                                        </li>
                                        <li><Link to="/about">About</Link></li>
                                        <li><Link to="/products">Products</Link></li>
                                        <li><Link to="/faq">FAQ</Link></li>
                                        {/*<li><Link to="/blog">Blog</Link></li>*/}
                                    </ul>
                                </Inner>
                                <SubscribeForm/>
                                <div className="footer-buttons">
                                    <button onClick={goToTop} className="button">Back to the top <Arrow/></button>
                                    <Social>
                                        <a target="_blank" href="https://www.facebook.com/dickiesaustralia/"
                                           rel="noreferrer noopener"><Facebook/>Facebook</a>
                                        <a target="_blank" href="https://www.instagram.com/dickiesaustralia/"
                                           rel="noreferrer noopener"><Instagram/>Instagram</a>
                                    </Social>
                                </div>
                            </Wrapper>
                            <div className="cred"><p>© Dickies {new Date().getFullYear()}{' '}|{' '}
                                <Link to="/privacy-policy">Privacy
                                    Policy</Link></p>
                                <p>Dickies is a brand of <a href="https://simba.global/" target="_blank"
                                                            rel="noreferrer noopener">
                                    Simba Global</a>
                                </p>
                            </div>
                        </footer>
                    </Container>
                </Holder>
            }
        </>
    )
}

export default Footer
