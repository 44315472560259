import React from "react";
import PropTypes from "prop-types";
import '../utils/fontface.css';

import GlobalStyles from "../components/atoms/GlobalStyles";
import {ThemeProvider} from "styled-components";
import {theme} from "../utils/styling";
import PopupForm from "../components/organisms/PopupForm";
import PopupForm2 from "../components/organisms/PopupForm2";
import Transition from "../components/atoms/Transition";
import CookiesConsent from "../components/organisms/CookiesConsent";
import ColourSchemes from "../components/organisms/ColourSchemes";

function Index({location, children}) {
    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyles/>
                <ColourSchemes path={location.pathname}>
                    <Transition location={location}>
                        <main>{children}</main>
                    </Transition>
                </ColourSchemes>
                <CookiesConsent/>
                <PopupForm/>
                <PopupForm2/>
            </>
        </ThemeProvider>
    );
}

Index.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Index;


