import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import throttle from 'lodash.throttle';

const Holder = styled.div`
  width: 100%;
  @media ( ${props => props.theme.breakpoints.md} ) {
    transition: transform 0.25s ease-in-out;
    transform: ${props => props.show ? 'translateY(0)' : 'translateY(-50px)'};
  }

  .header-grid {
    position: relative;
    z-index: 2;

    .logo-holder svg path,
    .logo-holder svg rect {
      fill: ${props => props.transparent ? props.theme.colours.white : props.theme.colours.black4};
      transition: fill 0.5s ease-in-out;
    }

    .menu-icon svg path {
      stroke: ${props => props.transparent ? props.theme.colours.white : props.theme.colours.grey3};
      transition: stroke 0.5s ease-in-out;
    }

    &.nav-open {
      .logo-holder svg path,
      .logo-holder svg rect {
        fill: ${props => props.theme.colours.black4};
        transition: fill 0.5s ease-in-out;
      }

      .menu-icon svg path {
        stroke: ${props => props.theme.colours.grey3};
        transition: stroke 0.5s ease-in-out;
      }
    }

    .navigation {
      ul {
        @media ( ${props => props.theme.breakpoints.md} ) {
          color: ${props => props.transparent ? props.theme.colours.white : props.theme.colours.black4};
          transition: color 0.5s ease-in-out;
        }
      }
    }

  }
`;

function HeaderScrollTransition({children}) {
    const [show, setShow] = useState(true);
    const [lastScrollTop, setLastScrollTop] = useState(0);

    useEffect(() => {

        const onScroll = () => {
            let st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > lastScrollTop) {
                // downscroll
                setShow(false);
            } else {
                // upscroll
                setShow(true);
            }
            setLastScrollTop(st <= 0 ? 0 : st);
        }

        const throttledCount = throttle(onScroll, 100);
        window.addEventListener('scroll', throttledCount);
        return () => window.removeEventListener('scroll', throttledCount);

    }, [lastScrollTop]);


    return (
        <Holder show={show}>
            {children}
        </Holder>
    )
}

export default HeaderScrollTransition;