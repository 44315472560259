import React, {useState} from "react"
import styled from 'styled-components'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from "formik";

const Holder = styled.div`
  padding: 1rem;
  width: 100%;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    padding: 0 1rem;
  }
`

const SubmitHolder = styled.div`
  grid-column: 1/-1;
  padding-top: 1rem;
  width: 100%;
  height: 100%;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    padding-top: 2rem;
  }

  input {
    width: 100%;
  }
`

const validationSchema = Yup.object().shape({
    question: Yup.string().required('* Please ask a question'),
    fullName: Yup.string().required('* Full name is required'),
    email: Yup.string().email('* Enter a valid email').required('* Email is required'),
})

const encode = data => new URLSearchParams(data).toString()

function ContactForm() {
    const [submitMsg, submitMsgHandler] = useState()

    return (
        <Holder>
            {typeof submitMsg === 'boolean' ?
                (submitMsg ?
                        <p className="message">Thankyou for your submission!</p>
                        :
                        <p className="message">Oh no! Something went wrong. Let's go back.</p>
                ) :
                <Formik initialValues={{question: '', fullname: '', email: ''}}
                        validationSchema={validationSchema}
                        onSubmit={(values, {setSubmitting}) => {
                            fetch('/', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                },
                                body: encode({
                                    'form-name': 'subscribe-form',
                                    ...values,
                                }),
                            })
                                .then(({ok, statusText}) => {
                                    if (!ok) {
                                        throw new Error(statusText);
                                    }
                                    submitMsgHandler(true)
                                    setSubmitting(false)
                                })
                                .catch((error) => {
                                    submitMsgHandler(false)
                                    setSubmitting(false)
                                })
                        }}
                >{({isSubmitting}) => (
                    <Form
                        className="form"
                        method="post"
                        name="subscribe-form"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                    >

                        <div className="fieldset email">
                            <label htmlFor="email" className="label">
                                Email
                            </label>
                            <Field className="input" type="text" name="email" placeholder="Email"/>
                            <ErrorMessage
                                className="errorMsg"
                                name="email"
                                component="small"
                            />
                        </div>

                        <div className="fieldset fullName">
                            <label htmlFor="fullName" className="label">
                                Full Name
                            </label>
                            <Field className="input" type="text" name="fullName" placeholder="Full Name"/>
                            <ErrorMessage
                                className="errorMsg"
                                name="fullName"
                                component="small"
                            />
                        </div>

                        <div className="fieldset question">
                            <label htmlFor="question" className="label">
                                Message
                            </label>
                            <Field className="input" type="text" name="question" placeholder="Write your message"/>
                            <ErrorMessage
                                className="errorMsg"
                                name="question"
                                component="small"
                            />
                        </div>
                        <SubmitHolder>
                            <input
                                name="submit"
                                type="submit"
                                disabled={isSubmitting}
                                value="Submit"
                                className="button giant blue"
                                style={{cursor: 'pointer'}}
                            />
                        </SubmitHolder>
                    </Form>
                )}
                </Formik>
            }
        </Holder>
    )
}

export default ContactForm
