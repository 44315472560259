import React, {useEffect, useState} from 'react'
import Navigation from './Navigation'
import styled from 'styled-components'
import HeaderScrollTransition from '../atoms/HeaderScrollTransition'
import SmallNavigation from './SmallNavigation'
import TickerTape from './TickerTape'
import useWindowSize from '../../hooks/useWindowSize'
import {useStore} from '../../utils/store'
import PropTypes from 'prop-types'

const Holder = styled.div`
  position: fixed;
  gap: 12px;
  z-index: 500;
  width: 100%;
  justify-content: center;
  pointer-events: none;

  .Header {
    padding-top: 12px;
  }
`

const Banner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colours.blue};
  color: ${props => props.theme.colours.offwhite};
  padding: 0.5rem 0;
  pointer-events: auto;

  p, button {
    margin: 0 ! important;
    font-family: "ABC Social Condensed Bold";
    ${(props) => props.theme.fluidType(-3)} !important;
    text-transform: uppercase;
  }

  button {
    text-decoration: underline;
  }
`

function Header({showAnnouncement, showHeader}) {
    const size = useWindowSize()
    const openContact = useStore(state => state.openContact)

    const [mobile, setMobile] = useState(null)
    useEffect(() => {
        if (size.width < 992) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }, [size.width])

    return (
        <>
            {!showHeader &&
                <Holder>
                    {showAnnouncement ?
                        <HeaderScrollTransition>
                            {!mobile &&
                                <Banner>
                                    <p>Catch all the latest! <button className="button link"
                                                                     onClick={() => openContact()}>Subscribe</button> to
                                        our
                                        newsletter today!
                                    </p>
                                </Banner>
                            }
                            {mobile &&
                                <TickerTape>
                                    <span>Catch all the latest! Subscribe to our newsletter today!</span>
                                    <span>Catch all the latest! Subscribe to our newsletter today!</span>
                                    <span>Catch all the latest! Subscribe to our newsletter today!</span>
                                </TickerTape>
                            }
                            <header>
                                <Navigation/>
                                <SmallNavigation/>
                            </header>
                        </HeaderScrollTransition>
                        :
                        <header>
                            <Navigation/>
                            <SmallNavigation/>
                        </header>
                    }
                </Holder>
            }
        </>
    )
}

export default Header

Header.propTypes = {
    showAnnouncement: PropTypes.bool.isRequired
}