import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useCookies} from 'react-cookie'
import {useLocation} from '@reach/router' // this helps tracking the location
import {initializeAndTrack} from 'gatsby-plugin-gdpr-cookies'
import {ScrollRotate} from 'react-scroll-rotate'

const Holder = styled.div`
  position: fixed;
  bottom: 0;
  left: ${props => props.info ? '50%' : '100%'};
  transform: ${props => props.info ? 'translate(-50%, 0)' : 'translate(-100%, 0)'};
  z-index: 500;
  pointer-events: none;
  transition: all 350ms ease;
`

const Circle = styled.div`
  will-change: transform;
  display: flex;
  gap: ${props => props.info ? '2rem' : '0'};
  padding: ${props => props.info ? '0 3rem' : '0'};
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 6rem;
  min-width: 6rem;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  background-color: ${props => props.theme.colours.offwhite};
  color: ${props => props.theme.colours.black};
  font-family: "ABC Social Condensed Bold";
  text-transform: uppercase;
  cursor: ${props => props.info ? 'auto' : 'pointer'};
  pointer-events: all;
  transition: inherit;
  border-radius: ${props => props.info ? '0' : '50%'};
  margin: ${props => props.info ? '0' : '1rem'};
  @media ( ${props => props.theme.breakpoints.md} ) {
    border-radius: ${props => props.info ? '3rem' : '50%'};
    margin: 2rem;
  }
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .custom {
    text-transform: initial;
    cursor: auto;
    font-weight: normal;
    text-align: start;
    line-height: 1.1;
    font-family: "Vinila Variable";
    ${(props) => props.theme.fluidType(-3.5)};
    margin: 0;
    min-width: 10rem;
    @media ( ${props => props.theme.breakpoints.md} ) {
      min-width: 8rem;
    }
  }
`

const HiddenButton = styled.button`
  background-color: ${props => props.theme.colours.black};
  color: ${props => props.theme.colours.offwhite};
  border-radius: 2rem;
  text-transform: uppercase;
  ${(props) => props.theme.fluidType(0)};
  padding: 0.5rem 1.5rem !important;
`

export default function CookiesConsent() {
    const [show, setShow] = useState(true)
    const [info, setInfo] = useState(false)
    const [, setCookie] = useCookies(['gatsby-gdpr-google-analytics',])
    const location = useLocation()

    useEffect(() => {
        // console.log(cookies)
        setCookie('gatsby-gdpr-google-analytics', true)
        initializeAndTrack(location)
    }, [])

    if (!show) return null

    return (
        <Holder info={info}>
            {!info && <Circle info={info} onClick={() => setInfo(true)}>
                <ScrollRotate method={'perc'} loops={2}><p id="animation">We use<br/> cookies</p></ScrollRotate>
            </Circle>}
            {info && <Circle info={info}>
                <Inner>
                    <p className="custom">This website uses cookies to ensure you get the best
                        experience on our website.
                    </p>
                    <HiddenButton className="button link orange" onClick={() => setShow(!show)}>Okay</HiddenButton>
                </Inner>
            </Circle>}
        </Holder>
    )
}