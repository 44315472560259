import React, {useEffect, useState} from 'react'
import {ThemeProvider} from 'styled-components'
import PropTypes from 'prop-types'
import {theme} from '../../utils/styling'
import Header from '../molecules/Header'
import Footer from '../molecules/Footer'

const schemes = {
    home: {
        footBg: theme.colours.blue,
        footText: theme.colours.white
    },
    shop: {
        footBg: theme.colours.orange,
        footText: theme.colours.white
    },
    about: {
        footBg: theme.colours.yellow,
        footText: theme.colours.black
    },
    support: {
        footBg: theme.colours.offwhite,
        footText: theme.colours.black
    },
    products: {
        footBg: theme.colours.green,
        footText: theme.colours.offwhite
    },
}

function ColourSchemes({children, path}) {
    const [scheme, setScheme] = useState(schemes.home)
    const announcementPages = ['/', '/shop', '/products']
    const hideGlobals = ['/post/books-by-the-beach']

    useEffect(() => {
        if (path.includes('shop')) {
            setScheme(schemes.shop)
        } else if (path.includes('about')) {
            setScheme(schemes.about)
        } else if (path.includes('support')) {
            setScheme(schemes.support)
        } else if (path.includes('products')) {
            setScheme(schemes.products)
        } else {
            setScheme(schemes.home)
        }
    }, [path])

    return (
        <ThemeProvider theme={scheme}>
            <Header showHeader={hideGlobals.includes(path)} showAnnouncement={announcementPages.includes(path)}/>
            {children}
            <Footer showFooter={hideGlobals.includes(path)}/>
        </ThemeProvider>
    )
}

ColourSchemes.propTypes = {
    path: PropTypes.string.isRequired
}

ColourSchemes.defaultProps = {
    path: '/'
}

export default ColourSchemes