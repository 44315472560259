export const theme = {
    colours: {
        // Shades
        black: "#000000",
        darkgrey: '#555555',
        grey: "#E0E0E0",
        white: "#ffffff",
        offwhite: "#F6F4EA",
        transparentwhite: "hsla(0,0%,100%,.8)",
        // Colours
        green: "#568259",
        yellow: "#D6A529",
        orange: "#F27E3D",
        blue: "#10069F",
        cream: "#DDCBA4",
        chocolate: "#5E3318",
        purple: "#8A069F",
        // Accessibility and forms
        focus: "#4fcd90",
        error: "#d0021b",
    },
    typography: {
        min: 16,
        max: 24,
        minScreen: 400,
        maxScreen: 1370,
        scale: {
            min: 1.05,
            max: 1.125,
        },
    },
    // https://www.smashingmagazine.com/2016/05/fluid-typography/
    fluidType: (exp) => {
        return `
      font-size: ${
            theme.typography.min * Math.pow(theme.typography.scale.min, exp)
        }px;
      @media screen and (min-width: ${theme.typography.minScreen}px ) {
        font-size: calc( ${
            theme.typography.min * Math.pow(theme.typography.scale.min, exp)
        }px + (${
            theme.typography.max * Math.pow(theme.typography.scale.max, exp)
        } - ${
            theme.typography.min * Math.pow(theme.typography.scale.min, exp)
        })*(100vw - ${theme.typography.minScreen}px)/(${
            theme.typography.maxScreen
        } - ${theme.typography.minScreen}) );
      }
      @media screen and (min-width: ${theme.typography.maxScreen}px ) {
        font-size: ${
            theme.typography.max * Math.pow(theme.typography.scale.max, exp)
        }px;
      }
      `
    },
    breakpoints: {
        sm: "min-width: 576px",
        md: "min-width: 768px",
        lg: "min-width: 992px",
        xl: `min-width: 1200px`,
    },
}
