import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import {useStore} from '../../utils/store'

const Menu = styled.div`
  display: none;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    width: fit-content;
    margin: 0.5rem auto 0 auto;
    display: flex;
    column-gap: 0.5rem;
    pointer-events: auto;
  }
`

const Grid = styled.ul`
  box-shadow: 0 10px 10px -5px rgb(0 0 0 / 10%);
  margin: 0;
  padding: 4px;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  list-style: none;
  border-radius: 56px;
  background-color: ${props => props.theme.colours.transparentwhite};
  backdrop-filter: blur(16px);
  border: 1px solid rgba(0, 0, 0, .1);

  .blog-container {
    display: block;
    position: relative;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    ${(props) => props.theme.fluidType(-2)};
  }

  li {
    display: flex;
    align-items: center;
    height: 100%;


    a, p {
      margin: 0;
      padding: 0;
      border-radius: 230px;
      line-height: 1.5;
      text-decoration: none;
      ${(props) => props.theme.fluidType(-2)};
      color: ${props => props.theme.colours.darkgrey};
      box-shadow: none;
      width: 4rem;
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a[aria-current="page"] {
      background-color: ${props => props.theme.colours.grey};
      color: ${props => props.theme.colours.black};
    }
  }
`

export default function Navigation() {
    const openContact = useStore(state => state.openContact)

    return (
        <Menu className="Navigation">
            <Grid>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/shop">Shop</Link>
                </li>
                <li>
                    <Link to="/blog">Blog</Link>
                </li>
                <li>
                    <Link to="/about">About</Link>
                </li>
                <li>
                    <Link to="/faq">FAQ</Link>
                </li>
                <button onClick={() => openContact()} className="button blue">Subscribe</button>
            </Grid>
        </Menu>
    )
}

