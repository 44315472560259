import React from 'react';
import styled, {keyframes} from 'styled-components';
import PropTypes from 'prop-types';

const pan = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
`;

const Holder = styled.div`
  position: relative;
  width: 100%;
  -ms-overflow-style: none;
  white-space: nowrap;
`;

const Helper = styled.div`
  position: relative;
  visibility: hidden;
`;

const Inner = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  animation: ${pan} ${props => props.speed} linear infinite;
  background-color: ${props => props.theme.colours.blue};
  color: ${props => props.theme.colours.offwhite};
  ${(props) => props.theme.fluidType(-3)};
  font-family: "ABC Social Condensed Bold" !important;

  span {
    display: inline-block;
    margin: 0 2rem !important;
    padding: 0.5rem 0;
    text-transform: uppercase;
  }

  button {
    margin: 0 !important;
    ${(props) => props.theme.fluidType(-3)};
    text-decoration: underline;
  }
`;

function TickerTape({children, speed}) {
    return (
        <Holder>
            <Helper>{children}</Helper>
            <Inner speed={speed}>
                {children}
            </Inner>
        </Holder>
    )
}

TickerTape.propTypes = {
    speed: PropTypes.string,
    children: PropTypes.array.isRequired
};

TickerTape.defaultProps = {
    speed: '10s',
};

export default TickerTape;