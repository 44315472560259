import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useStore} from '../../utils/store'
import Cross from '../../assets/svg/circleCross.inline.svg'
import ContactForm from './ContactForm'


const Holder = styled.div`
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: flex-end;
  transform: ${props => props.show ? 'translateY(0%)' : 'translateY(200%)'};
  transition: all 0.25s ease-in-out;
    //backdrop-filter: ${props => props.show ? 'blur(8px)' : 'none'};
  @media ( ${props => props.theme.breakpoints.md} ) {
    align-items: center;
    justify-content: center;
  }
`

const Inner = styled.div`
  box-shadow: 0 10px 10px -5px rgb(0 0 0 / 10%);
  background-color: ${props => props.theme.colours.grey};
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
  position: relative;
  @media ( ${props => props.theme.breakpoints.md} ) {
    width: unset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 56rem;
    overflow: hidden;
    border-top: none;
  }

  h2 {
    margin: 1rem 6rem 0 1rem;
  }

  .submit-message {
    margin: 3rem 0;
    @media ( ${props => props.theme.breakpoints.md} ) {
      margin: 1rem 0;
    }
  }

  form {
    input[type="submit"] {
      ${(props) => props.theme.fluidType(-1)} !important;
    }
  }
`

const CloseHolder = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    position: absolute;
  }

  button {
    svg {
      width: 2rem;
      display: block;
    }
  }
`

function PopupForm() {
    const [submitMsg, setSubmitMsg] = useState(false)
    const contactOpen = useStore(state => state.contactOpen)
    const closeContact = useStore(state => state.closeContact)

    useEffect(() => {
        if (contactOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }
    })

    return (
        <Holder show={contactOpen}>
            <Inner>
                {!submitMsg && <h2>Subscribe to be notified<br/> of the latest posts.</h2>}
                <ContactForm submitMsg={submitMsg} submitMsgHandler={(message) => setSubmitMsg(message)}/>
                <CloseHolder>
                    <button aria-label="Close the pop-up" onClick={() => {
                        closeContact()
                    }} className="button link">
                        <Cross/>
                    </button>
                </CloseHolder>
            </Inner>
        </Holder>
    )
}

export default PopupForm