import React from 'react';
import styled from "styled-components";
import Ebay from '../../assets/svg/ebay.inline.svg'
import OzSale from '../../assets/svg/ozsale.inline.svg'
import Bunnings from '../../assets/svg/bunnings.inline.svg'
import {StaticImage} from "gatsby-plugin-image";

const Grid = styled.div`
  margin-top: ${props => props.form ? '1rem' : '0'};
  display: grid;
  padding: 1rem;
  row-gap: 2rem;
  column-gap: 1rem;
  justify-items: center;
  align-items: center;
  grid-template-columns: ${props => props.form ? '1fr 1fr 1fr' : '1fr 1fr 1fr'};
  @media (${(props) => props.theme.breakpoints.md}) {
    gap: ${props => props.form ? '2rem' : '1rem'};
  }

  a {
    svg, .gatsby-image-wrapper {
      height: auto;
      width: ${props => props.form ? '6rem' : '5rem'};
    }
  }
`

export default function Stockists({close, form}) {
    return (
        <Grid form={form}>
            <a target="_blank" rel="noreferrer noopener" aria-label="Bunnings" onClick={close}
               href="https://www.bunnings.com.au/search/products?q=dickies&sort=BoostOrder&page=1&pageSize=36&brandname=Dickies"><Bunnings/></a>
            <a target="_blank" rel="noreferrer noopener" aria-label="KG Electronics" onClick={close}
               href="https://www.kgelectronic.com.au/brand/dickies/">
                <StaticImage src='../../../static/images/kg-electronics.webp' alt='Kg Electronics'/>
            </a>
            <a target="_blank" rel="noreferrer noopener" aria-label="Kogan" onClick={close}
               href="https://www.kogan.com/au/dickies/">
                <StaticImage src='../../../static/images/kogan.png' alt='Kogan'/>
            </a>
            <a target="_blank" rel="noreferrer noopener" aria-label="MyDeal" onClick={close}
               href="https://www.mydeal.com.au/brands/dickies">
                <StaticImage src='../../../static/images/mydeal.png' alt='MyDeal'/>
            </a>
            <a target="_blank" rel="noreferrer noopener" aria-label="Matt Blatt" onClick={close}
               href="https://www.mattblatt.com.au/mb/dickies/">
                <StaticImage src='../../../static/images/matt-blatt.png' alt='Matt Blatt'/>
            </a>
            <a target="_blank" rel="noreferrer noopener" aria-label="Catch" onClick={close}
               href="https://www.catch.com.au/brand/dickies/">
                <StaticImage src='../../../static/images/catch.png' alt='Catch'/>
            </a>
            <a target="_blank" rel="noreferrer noopener" aria-label="Ebay" onClick={close}
               href="https://www.ebay.com.au/b/Dickies/bn_21823417"><Ebay/></a>
            <a target="_blank" rel="noreferrer noopener" aria-label="OzSale" onClick={close}
               href="https://www.ozsale.com.au/shop/search/Dickies"><OzSale/></a>
            <a target="_blank" rel="noreferrer noopener" aria-label="Woolworths" onClick={close}
               href="https://www.woolworths.com.au/shop/search/products?searchTerm=dickies"> <StaticImage
                src='../../../static/images/woolworths.png' alt='Catch'/></a>
        </Grid>
    );
};