import React, {useEffect, useState} from "react"
import styled from "styled-components";
import MenuOverlay from "./MenuOverlay";
import {useStore} from "../../utils/store";

const Menu = styled.div`
  border-radius: 2rem;
  margin-top: 1rem;
  column-gap: 0.5rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: none;
  }
`

const Grid = styled.ul`
  margin: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  list-style: none;
  z-index: 100;

  li {
    display: flex;
    align-items: center;
    border-radius: 2rem;
    backdrop-filter: blur(8px);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    pointer-events: auto;

    button, a {
      ${(props) => props.theme.fluidType(1)};
      background-color: ${props => props.theme.colours.transparentwhite};
      padding: 0.75rem 1.5rem;
      border-radius: 2rem;
      line-height: 1.5;
      text-decoration: none;
      box-shadow: none;
      transition: background-color 500ms ease;
    }

    a[aria-current="page"] {
      background-color: ${props => props.theme.colours.white};
    }
  }
`

export default function SmallNavigation() {
    const [navOpen, setNavOpen] = useState(false);
    const openContact = useStore(state => state.openContact)

    useEffect(() => {
        if (navOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    })

    return (
        <Menu className="Navigation">
            <Grid>
                <li>
                    <button className="button" onClick={() => setNavOpen(!navOpen)}>Menu</button>
                </li>
                <li>
                    <button onClick={() => openContact()} className="button blue">Subscribe</button>
                </li>
            </Grid>
            <MenuOverlay show={navOpen} clickHandler={() => setNavOpen(!navOpen)}/>
        </Menu>
    )
}

