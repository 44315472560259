import {createGlobalStyle} from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${props => props.theme.fluidType(0)};

    * {
      box-sizing: border-box;
    }
  }

  body {
    margin: 0;
    font-family: "Vinila Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: ${props => props.theme.colours.black};
    background-color: ${props => props.theme.colours.offwhite};
    font-variation-settings: 'wght' 375, 'GRAD' 88, 'wdth' 475;
    -webkit-tap-highlight-color: transparent;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: 1.1;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  p,
  ol, ul, li,
  code, kbd, pre, samp {
    line-height: 1.5;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: normal;

    a {
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
    }
  }

  h1, .h1 {
    ${(props) => props.theme.fluidType(6)};
  }

  h2, .h2 {
    ${(props) => props.theme.fluidType(4)};
  }

  h3, .h3 {
    ${(props) => props.theme.fluidType(2)};
  }

  h4, .h4 {
    ${(props) => props.theme.fluidType(1)};
  }

  h5, .h5 {
    ${(props) => props.theme.fluidType(0)};
  }

  h6, .h6 {
    ${(props) => props.theme.fluidType(-1)};
  }

  li {
    margin-top: 0;
    margin-bottom: 0;
  }

  small, p.small {
    ${(props) => props.theme.fluidType(-1)};
  }

  code, kbd, pre, samp {
    font-family: monospace;
    white-space: normal;
  }

  ul {
    padding-left: 1rem;
    list-style-type: disc;
  }

  ol {
    padding-left: 1rem;
    list-style-type: decimal;
  }

  video {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  // Specific to PrismicRichText component
  .block-img {
    img {
      width: 100%;
      height: auto;
      display: block;
      margin: 3rem 0;
    }
  }

  // Specific to PrismicRichText component
  .embed {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 3rem 0;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  em, i {
    font-style: italic;
  }

  strong, b {
    font-weight: bold;
  }

  blockquote {
    font-weight: bold;
    padding-left: 1rem;
  }

  a {
    color: inherit;
  }

  sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }

  sub {
    top: 0.4em;
  }

  label {
    ${(props) => props.theme.fluidType(-1)};
    line-height: 2;
    font-weight: normal;
    padding-left: 1rem;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    @media (${(props) => props.theme.breakpoints.md}) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      column-gap: 1rem;
      align-items: center;
    }
  }

  .fieldset {
    margin-top: 0.5rem;
    @media (${(props) => props.theme.breakpoints.md}) {
      margin-top: 1rem;
    }
  }

  .question {
    grid-column: 1/-1;
  }

  .email, .fullName {
    grid-column: 1/-1;
    @media (${(props) => props.theme.breakpoints.md}) {
      grid-column: unset;
    }
  }

  .text-input,
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
    display: block;
    font-family: inherit;
    ${(props) => props.theme.fluidType(0)};
    outline: none;
    border: 1px solid;
    padding: 0.5rem 1rem;
    border-radius: 3rem;
    line-height: 1.6;
    background-color: ${props => props.theme.colours.white};
    -webkit-appearance: none;
    width: 100%;

    &.border {
      border: 1px solid;
    }
  }

  ::placeholder {
    color: ${props => props.theme.colours.black};
    opacity: 0.6;
  }

  .file-input,
  input[type="file"],
  .radio-input,
  input[type="radio"],
  .checkbox-input,
  input[type="checkbox"],
  select {
    font-family: inherit;
  }

  // Select

  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 2rem;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    color: ${props => props.theme.colours.black};
    opacity: 0.6;
  }

  select::-ms-expand {
    display: none;
  }

  .fieldset.select {
    width: 100%;
    border-bottom: 1px solid;
    border-radius: 0;
    padding: 0;
    margin: 0.5rem 0;
    ${props => props.theme.fluidType(0)};
    cursor: pointer;
    line-height: 1.1;
    background-color: transparent;
    grid-template-areas: "select";
    display: grid;
    align-items: center;
    max-width: 15rem;

    &:after {
      content: "";
      width: 0.8rem;
      height: 0.5rem;
      background-color: ${props => props.theme.colours.black};
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
      justify-self: end;
    }

    select,
    &:after {
      grid-area: select;
    }
  }

  .button,
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
    width: min-content;
    height: min-content;
    display: inline-block;
    font-family: "ABC Social Condensed" !important;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 1rem;
    border-radius: 2rem;
    line-height: 1.5;
    text-decoration: none;
    ${(props) => props.theme.fluidType(-1)};
    white-space: nowrap;
    border: none;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    transition: transform 100ms ease;

    * {
      margin: 0;
      padding: 0;
    }

    &:active {
      text-decoration: none;
      transform: scale(0.975);
    }

    &.link {
      border: none;
      padding: 0;
      background-color: transparent;
      box-shadow: none;

      &:hover {
        transform: scale(1);
      }
    }

    &.large {
      padding: 1rem 3.75rem;
      border: none;
      background-color: transparent;
    }

    &.giant {
      padding: 1.25rem 3.75rem;
      border: none;
      border-radius: 10rem;
      background-color: transparent;
      ${(props) => props.theme.fluidType(6)};
      cursor: auto;
    }

    &.icon {
      padding: 0.5rem;

      svg {
        height: 1rem;
        width: auto;
        display: block;
      }
    }

    &.yellow, &.quilt-covers {
      background-color: ${props => props.theme.colours.yellow};
      color: ${props => props.theme.colours.black};
    }

    &.green, &.towels {
      background-color: ${props => props.theme.colours.green};
      color: ${props => props.theme.colours.offwhite};
    }

    &.orange, &.sheets {
      background-color: ${props => props.theme.colours.orange};
      color: ${props => props.theme.colours.black};
    }

    &.blue, &.top-of-bed {
      background-color: ${props => props.theme.colours.blue};
      color: ${props => props.theme.colours.offwhite};
    }

    &.chocolate, &.accessories {
      background-color: ${props => props.theme.colours.chocolate};
      color: ${props => props.theme.colours.offwhite};
    }
  }

  .errorMsg {
    color: ${(props) => props.theme.colours.error};
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`

export default GlobalStyle
