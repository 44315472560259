import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import PropTypes from 'prop-types'

const Holder = styled.div`
  z-index: 10;
  padding: 0 0.5rem;
  width: 100%;
  transform: ${props => props.show ? 'translateX(0%)' : 'translateX(-100%)'};
  transition: transform 0.25s ease-in-out;
`

const Grid = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  column-gap: 0.5rem;
  list-style: none;
  border-radius: 2rem;
  z-index: 100;
  height: 100%;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    pointer-events: auto;

    a {
      font-family: "ABC Social Condensed" !important;
      font-weight: bold;
      text-transform: uppercase;
      background-color: ${props => props.theme.colours.transparentwhite};
      backdrop-filter: blur(8px);
      padding: 0.25rem 4rem;
      border-radius: 10rem;
      line-height: 1.5;
      text-decoration: none;
      ${(props) => props.theme.fluidType(15)};
      transition: background-color 500ms ease;
      box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
    }

    a[aria-current="page"] {
      background-color: ${props => props.theme.colours.white};
    }
  }
`

export default function MenuOverlay({clickHandler, show}) {

    return (
        <Holder show={show}>
            <Grid>
                <li>
                    <Link to="/" onClick={clickHandler}>Home</Link>
                </li>
                <li>
                    <Link to="/shop" onClick={clickHandler}>Shop</Link>
                </li>
                <li>
                    <Link to="/blog" onClick={clickHandler}>Blog</Link>
                </li>
                <li>
                    <Link to="/about" onClick={clickHandler}>About</Link>
                </li>
                <li>
                    <Link to="/faq" onClick={clickHandler}>FAQ</Link>
                </li>
            </Grid>
        </Holder>
    )
}

MenuOverlay.propTypes = {
    clickHandler: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
}