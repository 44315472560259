import React from 'react';
import styled from 'styled-components';

const Holder = styled.div`
  form {
    display: block;

    label {
      ${(props) => props.theme.fluidType(0)};
      font-variation-settings: 'wght' 550, 'GRAD' 88, 'wdth' 375;
      line-height: 1.5;
      margin-bottom: 0.5rem;
      margin-top: 0;
      padding: 0;
    }

    input {
      border-radius: 2rem;
      margin-top: 0.25rem;
      padding-left: 1rem;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      border: none;

      &::placeholder {
        color: ${props => props.theme.footBg};
        opacity: 0.75;
      }
    }

    .clear {
      display: none;
    }
  }
`;

function SubscribeForm() {
    return (
        <Holder>
            <form
                action="https://global.us14.list-manage.com/subscribe/post?u=f016cbf064c3b79219809d2ad&amp;id=0f3bc6e048&amp;f_id=00469ce0f0"
                method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate"
                target="_self">
                <div id="mc_embed_signup_scroll">
                    <div className="mc-field-group">
                        <label htmlFor="mce-EMAIL">Signup to our newsletter
                        </label>
                        <input type="email" placeholder="Email" name="EMAIL" className="required email"
                               id="mce-EMAIL" required/>
                        <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
                    </div>
                    <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                        <input type="text"
                               name="b_f016cbf064c3b79219809d2ad_0f3bc6e048"
                               tabIndex="-1"/>
                    </div>
                    <div className="clear"><input type="submit" value="Subscribe" name="subscribe"
                                                  id="mc-embedded-subscribe" className="button"/></div>
                </div>
            </form>
        </Holder>
    )
}

export default SubscribeForm;